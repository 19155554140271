<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 10 10"
    fill="#C4C4C4"
  >
    <path
      d="M2.25751 9.65188C2.01626 9.77563 1.74251 9.55876 1.79126 9.28188L2.31001 6.32563L0.108138 4.22813C-0.0974871 4.03188 0.00938788 3.67313 0.285013 3.63438L3.34626 3.19938L4.71126 0.495007C4.83439 0.251257 5.16751 0.251257 5.29064 0.495007L6.65564 3.19938L9.71689 3.63438C9.99251 3.67313 10.0994 4.03188 9.89314 4.22813L7.69189 6.32563L8.21064 9.28188C8.25939 9.55876 7.98564 9.77563 7.74439 9.65188L5.00001 8.24188L2.25689 9.65188H2.25751Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'StarIcon',
  props: {
    size: {
      type: Number,
      default: 10
    }
  }
}
</script>
