<template>
  <div
    class="product-card"
    @click="goToOrderPage"
  >
    <a
      class="product-card__content"
    >
      <div
        class="product-card-image__wrapper"
      >
        <img
          class="product-card__image"
          :src="product.image"
          :alt="product.name"
          width="133px"
        >
        <div
          v-if="product.ribbon"
          class="product-card__ribbon"
          v-html="product.ribbon"
        />
      </div>

      <div
        class="product-card__title"
      >
        {{ product.subtitle }}
      </div>
      <div
        class="product-card__description"
      >
        {{ product.title }}
      </div>
      <star-rating
        :rating="Number(product.rating)"
      />
      <div
        class="product-card__price"
      >
        {{ product.price }}
      </div>
    </a>
    <a
      class="wfw-button wfw-button--primary product-card__button"
    >
      {{ $t('make-order') }}
    </a>
  </div>
</template>

<script>
import StarRating from './star/StarRating.vue'
import { ACTION_CLICK_ON_PRODUCT_BUNDLE } from '@/constants/flow-log.constants'
import { updateExternalLinks } from '@/services/code.service'
export default {
  name: 'ProductBundleItem',
  components: {
    StarRating
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    isPreview () {
      return !this.$store.state.session.slug
    }
  },
  methods: {
    goToOrderPage () {
      const { isPreview, product } = this
      const { slug } = this.$store.state.session

      if (isPreview) return
      const action = ACTION_CLICK_ON_PRODUCT_BUNDLE
      const bundleId = product.id
      if (!product.use_external_link) {
        this.$store.dispatch('session/log', { action, bundleId })
        this.$router.push({ name: 'order', params: { bundleId } })
      } else {
        const payload = updateExternalLinks(product.external_link, { slug, bundleId })
        window.open(payload, '_blank').focus()
        this.$store.dispatch('session/log', { action, bundleId, payload })
      }
    }
  }
}
</script>

<style
  scoped
  lang="scss"
>
  .product-card {
    width: 150px;
    text-align: center;
    font-weight: 500;
    font-size: 0.857em;
    line-height: 120%;
    cursor: pointer;

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      pointer-events: none;
    }

    &__image {
      margin-bottom: 14px;
      border: 4px solid var(--inactive-highlight-color);
      transition: transform 0.3s;
    }

    &__title {
      margin-bottom: 6px;
      color: var(--highlight-color);
      min-height: 14px;
    }

    &__description {
      margin-bottom: 6px;
    }

    &__price {
      margin: 12px 0;
      font-weight: bold;
      font-size: 1em;
    }

    &__button {
      visibility: hidden;
      opacity: 0;
      transition: 0.3s;
    }

    &:hover {
      .product-card__image {
        filter: drop-shadow(0 5px 14px rgba(0, 0, 0, 0.25));
        background-color: var(--highlight-color);
      }

      .product-card__description {
        text-decoration: underline;
      }

      .product-card__button {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s, opacity 0.3s;
      }
    }

    .product-card-image__wrapper {
      position: relative;
      overflow: hidden;
      min-height: 200px;

      img {
        min-height: 200px;
      }

      .product-card__ribbon {
        position: absolute;
        background: var(--highlight-color);
        color: var(--inverse-color);
        height: 1.25em;
        font-size: 0.9em;
        word-break: keep-all;
        white-space: nowrap;
        width: 150%;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: rotate(341deg);
        bottom: 29%;
        left: -25%;
      }
    }

    @media screen and (max-width: 992px) {
      &__content {
        pointer-events: all;
      }

      &__button {
        display: none;
      }
    }
  }
</style>
