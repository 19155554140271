<template>
  <div
    class="modal-embed-footer__wrapper"
  >
    <div>
      Ein Angebot von Checkout-Panda.
      <a
        target="_blank"
        href="https://checkout-panda.ch/datenschutz/"
      > Datenschutz </a> -
      <a
        target="_blank"
        href="https://checkout-panda.ch/impressum/"
      >
        Impressum
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalEmbedFooter'
}
</script>

<style
  scoped
  lang="scss"
>
.modal-embed-footer__wrapper {
  font-size: 0.9em;
  display: flex;
  height: 20px;
  justify-content: center;
  align-items: center;
  color: var(--secondary-color);

  @media screen and (max-width: 768px) {
    font-size: 0.7em;
  }

  a:link,
  a:active,
  a:visited {
    color: blue;
  }

}
</style>
