<template>
  <div
    class="product-card-swiper"
  >
    <carousel
      ref="carousel"
      :per-page-custom="[[576, 3], [768, 4], [992, 6]]"
      navigation-enabled
      :navigation-next-label="`<span class='carousel-arrow'>${svgArrow}</span>`"
      :navigation-prev-label="`<span class='carousel-arrow inverse'>${svgArrow}</span>`"
      pagination-active-color="var(--highlight-color)"
      pagination-color="var(--secondary-color)"
      :loop="true"
      @pageChange="detectVisibleProducts"
    >
      <slide
        v-for="product of products"
        :key="product.id"
      >
        <product-bundle-item
          :product="product"
        />
      </slide>
    </carousel>
  </div>
</template>

<script>
import ProductBundleItem from './ProductBundleItem.vue'
import { Carousel, Slide } from 'vue-carousel'

export default {
  name: 'ProductBundleCarousel',
  components: {
    ProductBundleItem,
    Carousel,
    Slide
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    products: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      isMounted: false,
      showedProducts: [],
      svgArrow: `<svg width='11' height='11' viewBox='0 0 11 11' xmlns='http://www.w3.org/2000/svg'>
                      <path d='M8.55568 5.07078L3.66054 0.17573C3.54732 0.0624223 3.39618 0 3.23503 0C3.07388 0 2.92274 0.0624223 2.80952 0.17573L2.44903 0.536134C2.21445 0.770977 2.21445 1.15267 2.44903 1.38715L6.5596 5.49772L2.44447 9.61285C2.33125 9.72616 2.26874 9.8772 2.26874 10.0383C2.26874 10.1995 2.33125 10.3506 2.44447 10.464L2.80496 10.8243C2.91827 10.9376 3.06932 11 3.23047 11C3.39162 11 3.54276 10.9376 3.65598 10.8243L8.55568 5.92475C8.66917 5.81108 8.7315 5.65932 8.73114 5.49799C8.7315 5.33603 8.66917 5.18436 8.55568 5.07078Z'/>
                  </svg>`
    }
  },
  mounted () {
    this.detectVisibleProducts()
  },
  methods: {
    detectVisibleProducts (page = 0) {
      const { currentPerPage } = this.$refs.carousel
      const { products, showedProducts } = this
      const start = page * currentPerPage
      const end = start + currentPerPage
      const showed = products.slice(start, end).map(_ => _.id)
      this.showedProducts = [...new Set([...showedProducts, ...showed])]
      this.$emit('sawProducts', this.showedProducts)
    }
  }
}
</script>

<style
  lang="scss"
  scoped
>
  .product-card-swiper {
    margin: 40px 16px;

    &__title {
      margin-bottom: 30px;
      font-weight: 800;
      font-size: 1.285em;
      line-height: 22px;
    }

    @media screen and (max-width: 992px) {
      margin: 30px 16px;

      .swiper-container {
        margin: 0 -16px;
        padding: 0;
      }
    }
  }

</style>
