<template>
  <div
    class="rating"
  >
    <div
      class="rating__stars"
    >
      <star-icon
        v-for="index in 5"
        :key="index"
        :size="iconSize"
        class="star"
        :class="{'star--gray': index > rating}"
      />
    </div>
    <div
      class="rating__number"
      :class="size"
    >
      ({{ rating }}/5)
    </div>
  </div>
</template>

<script>
import StarIcon from './StarIcon.vue'

export default {
  name: 'StarRating',
  components: {
    StarIcon
  },
  props: {
    rating: {
      type: Number,
      default: 0
    },
    size: {
      type: String,
      default: 'sm',
      validator: prop => ['sm', 'lg'].includes(prop)
    }
  },
  computed: {
    iconSize () {
      const { size } = this
      return size === 'lg' ? 20 : 10
    }
  }
}
</script>

<style scoped lang="scss">
  .rating {
    display: flex;
    align-items: center;

    &__stars {
      .star {
        fill: var(--highlight-color);
        margin-right: 3px;

        &--gray {
          fill: var(--secondary-color);
        }
      }
    }

    &__number {
      font-weight: 500;
      font-size: 0,714;;
      line-height: 12px;
      color: var(--secondary-color);

      &.lg {
        font-size: 1.142rem;
      }
    }
  }
</style>
