import axios from '@/plugins/axios'
import { ACTION_SAW_PRODUCT_BUNDLES, ACTION_RESET_WATCHED_PRODUCTS } from '@/constants/flow-log.constants'

export const state = () => ({
  slug: null,
  loggedProductsIds: [],
  lastLoggedAction: null
})

export const mutations = {
  setSession (state, payload) {
    state.slug = payload
  },
  setShowedProducts (state, payload) {
    state.showedProducts = payload
  },
  setLastAction (state, payload) {
    state.showedProducts = payload
  }
}

export const actions = {
  /**
   * Make new flow session
   *
   * @param commit
   * @param state
   * @param uuid
   * @param query
   * @returns {Promise<void>}
   */
  async fetch ({ commit, state }, query) {
    const { slug } = state
    const { preview, flow_uuid: flowSlug, shop_uuid: shopSlug, onCouponPage } = query

    /**
     * Do not send request in preview or do not send duplications
     */
    if (preview || slug) return

    /**
     * Session slug exist only in coupons page
     */
    if (onCouponPage) {
      commit('setSession', query.sessionSlug)
      return
    }

    const { data: consumerId } = await axios.post('/v1/embed/consumers', query)

    const { data: sessionSlug } = await axios.post(`/v1/embed/flow-sessions/${flowSlug}`, {
      consumer_id: consumerId,
      shop_slug: shopSlug
    })

    commit('setSession', sessionSlug)

    if (query.promo_code) {
      axios.post('/v1/embed/sales', {
        flow_session: sessionSlug,
        shop: shopSlug,
        email: query.email,
        code: query.promo_code,
        order_id: query.order_id,
        order_value: query.order_value,
        order_currency: query.order_currency
      })
    }
  },
  /**
   * Send log data to api
   *
   * @param commit
   * @param state
   * @param payload
   */
  log ({ commit, state }, payload) {
    const { slug } = state
    if (!slug) return
    commit('setLastAction', payload.action)
    axios.post(`/v1/flow-sessions/${slug}/log`, payload)
  },
  /**
   * Unique function for logging watched bundles
   *
   * @param commit
   * @param state
   * @param payload
   */
  logShowedProducts ({ commit, state }, payload) {
    const { slug, lastLoggedAction } = state
    if (!slug) return

    let { loggedProductsIds } = state
    if (ACTION_RESET_WATCHED_PRODUCTS.includes(lastLoggedAction)) {
      loggedProductsIds = []
      commit('setShowedProducts', loggedProductsIds)
    }

    const action = ACTION_SAW_PRODUCT_BUNDLES
    const notLoggedBundles = payload.filter(_ => !loggedProductsIds.includes(_.id))
    commit('setLastAction', action)
    commit('setShowedProducts', [...loggedProductsIds, ...notLoggedBundles.map(_ => _.id)])

    if (!notLoggedBundles.length) return

    const request = {
      action,
      product_bundles: notLoggedBundles
    }

    axios.post(`/v1/flow-sessions/${slug}/log`, request)
  }
}
