import Vue from 'vue'
import App from './App.vue'
import '@/styles/typography.scss'
import '@/styles/global.scss'
import 'iframe-resizer/js/iframeResizer.contentWindow.min'
import router from './router'
import { i18n } from './plugins/i18n'
import './plugins/axios'
import store from './store'
import * as Sentry from '@sentry/vue'
import configValidation from '@/plugins/validation'
import VueGtm from '@gtm-support/vue2-gtm'

configValidation(Vue, i18n)

Vue.config.productionTip = false

const gTagId = process.env.VUE_APP_GOOGLE_TAG_ID

if (gTagId) {
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GOOGLE_TAG_ID
  })
}

const sentryDNS = process.env.VUE_APP_SENTRY_DNS
if (sentryDNS) {
  Sentry.init({
    Vue,
    dsn: sentryDNS
  })
}

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
