import axios from '@/plugins/axios'

export const state = () => ({
  loaded: false,
  showCoupons: false,
  showW4wCoupons: false,
  hideProductsFromStartPage: false,
  highLevelProducts: [],
  lowLevelProducts: [],
  description: '',
  voucherText: ''
})

export const mutations = {
  set (state, result) {
    state.showCoupons = result.show_coupon_link
    state.showW4wCoupons = result.use_w4w_coupon_btn
    state.hideProductsFromStartPage = result.hide_product_from_start_page
    state.description = result.description
    state.voucherText = result.voucher_text

    if (result.show_product_bundles) {
      state.highLevelProducts = result.products.filter(_ => _.is_high_priority)
      state.lowLevelProducts = result.products.filter(_ => !_.is_high_priority)
    }
  }
}

export const actions = {
  async fetch ({ commit, state }, slug) {
    if (this.state.loaded) return
    const { data } = await axios.get(`/v1/embed/flows/${slug}`)
    commit('set', data)
  }
}
