<template>
  <div
    class="success-banner"
  >
    <SuccessIcon
      class="success-banner__icon"
    />
    <div>
      <div
        class="success-banner__title"
      >
        {{ $t('form.success.title') }}
      </div>
      <div
        class="success-banner__description"
      >
        {{ $t('form.success.description') }}
      </div>
    </div>
  </div>
</template>

<script>
import SuccessIcon from './SuccessIcon.vue'

export default {
  name: 'SuccessBanner',
  components: {
    SuccessIcon
  }
}
</script>

<style scoped lang="scss">
  .success-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    padding: 20px;
    background: rgba(40, 180, 70, 0.15);

    &__icon {
      width: 50px;
      height: 50px;
      margin-right: 16px;
    }

    &__title {
      margin-bottom: 6px;
      font-weight: 800;
      font-size: 1.142rem;
      line-height: 20px;
      color: #28b446;
    }

    &__description {
      font-weight: 600;
      font-size: 1em;
      line-height: 17px;
    }

    @media screen and (max-width: 768px) {
      padding: 14px;

      &__icon {
        width: 45px;
        height: 45px;
      }

      &__title {
        font-size: 1em;
      }

      &__description {
        font-size: 0.93em;
      }
    }
  }
</style>
