<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
  >
    <path
      d="M24.9999 49.6774C11.3548 49.6774 0.32251 38.6451 0.32251 25C0.32251 11.3548 11.3548 0.322571 24.9999 0.322571C38.6451 0.322571 49.6773 11.3548 49.6773 25C49.6773 38.6451 38.6451 49.6774 24.9999 49.6774ZM24.9999 3.2258C12.9515 3.2258 3.22574 12.9516 3.22574 25C3.22574 37.0484 12.9515 46.7742 24.9999 46.7742C37.0483 46.7742 46.7741 37.0484 46.7741 25C46.7741 12.9516 37.0483 3.2258 24.9999 3.2258Z"
      fill="#28B446"
    />
    <path
      d="M22.0966 35.7419L9.46753 23.1129L11.4998 21.0806L22.0966 31.6774L38.4998 15.2742L40.5321 17.3064L22.0966 35.7419Z"
      fill="#28B446"
    />
  </svg>
</template>

<script>
export default {
  name: 'SuccessIcon'
}
</script>
