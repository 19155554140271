import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: {}
})

const loadedLanguages = []

export async function loadLanguageAsync (lang) {
  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return;
  }
  // If the language hasn't been loaded yet
  const messages = await import(/* webpackChunkName: "lang-[request]" */ `@/locales/${lang}.json`)

  i18n.setLocaleMessage(lang,messages)
}
