<template>
  <svg
    width="75"
    height="75"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.4375 3.75C24.4375 5.0061 23.8186 6.1687 22.7876 6.86255L19.3 9.2063C18.8689 9.50635 18.2874 9.3938 18.0063 8.9624C17.7063 8.53125 17.8186 7.94995 18.25 7.6687L21.7375 5.30615C22.2439 4.94995 22.5625 4.36865 22.5625 3.75C22.5625 2.71875 21.7188 1.875 20.6875 1.875C19.8438 1.875 19.0938 2.4375 18.8689 3.26245L17.4062 8.68115C17.2937 9.09375 16.9187 9.375 16.5063 9.375C16.4312 9.375 16.3374 9.3562 16.2625 9.3374C16.1687 9.3186 16.075 9.26245 16 9.2063C15.925 9.26245 15.8313 9.3186 15.7375 9.3374C15.6626 9.3562 15.5688 9.375 15.4939 9.375C15.0813 9.375 14.7063 9.09375 14.594 8.68115L13.1313 3.26245C12.9062 2.4375 12.1562 1.875 11.3125 1.875C10.2812 1.875 9.4375 2.71875 9.4375 3.75C9.4375 4.36865 9.7561 4.94995 10.2625 5.30615L13.75 7.6687C14.1814 7.94995 14.2937 8.53125 13.9937 8.9624C13.7126 9.3938 13.1311 9.50635 12.7 9.2063L9.2124 6.86255C8.18115 6.1687 7.5625 5.0061 7.5625 3.75C7.5625 1.6875 9.25 0 11.3125 0C13 0 14.5 1.1438 14.9312 2.7749L16 6.7124L17.0688 2.7749C17.5 1.1438 19 0 20.6875 0C22.75 0 24.4375 1.6875 24.4375 3.75Z"
      fill="var(--gift-color-1)"
    />
    <path
      d="M24.4375 3.75C24.4375 5.0061 23.8186 6.1687 22.7876 6.86255L19.3 9.2063C18.8689 9.50635 18.2874 9.3938 18.0063 8.9624C17.7063 8.53125 17.8186 7.94995 18.25 7.6687L21.7375 5.30615C22.2439 4.94995 22.5625 4.36865 22.5625 3.75C22.5625 2.71875 21.7188 1.875 20.6875 1.875C19.8438 1.875 19.0938 2.4375 18.8689 3.26245L17.4062 8.68115C17.2937 9.09375 16.9187 9.375 16.5063 9.375C16.4312 9.375 16.3374 9.3562 16.2625 9.3374C16.1687 9.3186 16.075 9.26245 16 9.2063V6.7124L17.0688 2.7749C17.5 1.1438 19 0 20.6875 0C22.75 0 24.4375 1.6875 24.4375 3.75Z"
      fill="var(--gift-color-6)"
    />
    <path
      d="M28.1875 15V29.1875C28.1875 30.7437 26.9314 32 25.375 32H17.875L16 30.125L14.125 32H6.625C5.0686 32 3.8125 30.7437 3.8125 29.1875V15H28.1875Z"
      fill="var(--gift-color-3)"
    />
    <path
      d="M28.1875 15V29.1875C28.1875 30.7437 26.9314 32 25.375 32H17.875L16 30.125V15H28.1875Z"
      fill="var(--gift-color-5)"
    />
    <path
      d="M30.0625 10.3125V17.8125C30.0625 18.3374 29.6501 18.75 29.125 18.75H2.875C2.34985 18.75 1.9375 18.3374 1.9375 17.8125V10.3125C1.9375 8.7561 3.1936 7.5 4.75 7.5H14.125L16 9.375L17.875 7.5H27.25C28.8064 7.5 30.0625 8.7561 30.0625 10.3125Z"
      fill="var(--gift-color-4)"
    />
    <path
      d="M30.0625 10.3125V17.8125C30.0625 18.3374 29.6501 18.75 29.125 18.75H16V9.375L17.875 7.5H27.25C28.8064 7.5 30.0625 8.7561 30.0625 10.3125Z"
      fill="var(--gift-color-3)"
    />
    <path
      d="M8.77466 14.7253C8.40845 14.3591 8.40845 13.7659 8.77466 13.3997L14.3997 7.77466C14.7659 7.40845 15.3591 7.40845 15.7253 7.77466C16.0916 8.14087 16.0916 8.73413 15.7253 9.10034L10.1003 14.7253C9.73413 15.0916 9.14087 15.0916 8.77466 14.7253Z"
      fill="var(--gift-color-2)"
    />
    <path
      d="M21.8997 14.7253L16.2747 9.10034C15.9084 8.73413 15.9084 8.14087 16.2747 7.77466C16.6409 7.40845 17.2341 7.40845 17.6003 7.77466L23.2253 13.3997C23.5916 13.7659 23.5916 14.3591 23.2253 14.7253C22.8591 15.0916 22.2659 15.0916 21.8997 14.7253Z"
      fill="var(--gift-color-1)"
    />
    <path
      d="M14.125 7.5H17.875V32H14.125V7.5Z"
      fill="var(--gift-color-2)"
    />
    <path
      d="M16 7.5H17.875V32H16V7.5Z"
      fill="var(--gift-color-1)"
    />
  </svg>
</template>

<script>
export default {
  name: 'CouponsGiftImage'
}
</script>
<style
  scoped
  lang="scss"
>
#bottom-box {
  fill: var(--highlight-color);
}
</style>
