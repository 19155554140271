import {
  ValidationObserver,
  ValidationProvider,
  extend,
  configure,
  setInteractionMode
} from 'vee-validate'
import {
  email,
  required
} from 'vee-validate/dist/rules'

export default (Vue, i18n) => {
  setInteractionMode('passive')

  configure({
    defaultMessage: (field, values) => {
      values._field_ = i18n.t(`fields.${field}`)
      return i18n.t(`validations.${values._rule_}`, values)
    }
  })

  extend('required', required)
  extend('email', email)

  Vue.component('ValidationObserver', ValidationObserver)
  Vue.component('ValidationProvider', ValidationProvider)
}
