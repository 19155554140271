<template>
  <div
    id="app"
    :style="dynamicVariables"
  >
    <div
      class="iframe-resizer__wrapper"
    >
      <router-view />
      <modal-embed-footer
        v-if="isModal"
      />
    </div>
  </div>
</template>

<script>
import ModalEmbedFooter from '@/components/ModalEmbedFooter'
import * as WebFont from 'webfontloader'

export default {
  name: 'App',
  components: { ModalEmbedFooter },
  data () {
    return {
      theme: null
    }
  },
  computed: {
    dynamicVariables () {
      const DEFAULT_FONT = 'Monserrat, Helvetica, Arial, sans-serif'
      const {
        fontSize = 14,
        fontFamily = 'Monserrat, Helvetica, Arial, sans-serif',
        backgroundColor = '#ffffff',
        primaryColor = '#000000',
        secondaryColor = '#7f7f7f',
        fadedColor = '#F5F5F5',
        highlightColor = '#f4a333',
        inactiveHighlightColor = '#F4A333BD',
        inverseColor = '#ffffff',
        successColor = '#28b446',
        errorColor = '#dc4c3f',
        giftColor1 = '#FFD400',
        giftColor2 = '#FFE470',
        giftColor3 = '#FF7816',
        giftColor4 = '#FF9F00',
        giftColor5 = '#FF4B00',
        giftColor6 = '#FDBF00'
      } = this.theme || this.$route.query

      if (fontFamily !== DEFAULT_FONT) {
        WebFont.load({
          google: {
            families: [fontFamily]
          }
        })
      }

      return `
        --font: ${fontFamily};
        --font-size: ${fontSize}px;
        --background-color: ${backgroundColor};
        --primary-color: ${primaryColor};
        --secondary-color: ${secondaryColor};
        --faded-color: ${fadedColor};
        --highlight-color: ${highlightColor};
        --inactive-highlight-color: ${inactiveHighlightColor};
        --inverse-color: ${inverseColor};
        --success-color: ${successColor};
        --error-color: ${errorColor};
        --gift-color-1: ${giftColor1};
        --gift-color-2: ${giftColor2};
        --gift-color-3: ${giftColor3};
        --gift-color-4: ${giftColor4};
        --gift-color-5: ${giftColor5};
        --gift-color-6: ${giftColor6};
      `
    },
    isModal () {
      return this.$route.query.modal === 'true'
    }
  },
  mounted () {
    window.addEventListener('message', event => {
      if (this.$route.query.preview && event.data.call === 'updateTheme') {
        this.theme = event.data.value
      }
    })
  }
}
</script>

<style
    lang="scss"
>

body {
  margin: 0 !important;
}

#app {
  background-color: var(--background-color);
  color: var(--primary-color);
  font-size: var(--font-size);
}

.iframe-resizer__wrapper {
  padding-bottom: 1rem;
}

html, body, #app, input {
  font-family: var(--font);
  font-weight: 500;
}
</style>
