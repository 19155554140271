import Vue from 'vue'
import VueRouter from 'vue-router'
import FlowPage from '../views/FlowPage.vue'
import axios from '@/plugins/axios'
import store from '@/store'
import { loadLanguageAsync, i18n } from '../plugins/i18n'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'flow',
    component: FlowPage,
    props: true,
    beforeEnter: async ({ query }, from, next) => {
      const { preview, bundleId } = query
      if (preview && bundleId) {
        next({
          name: 'order',
          params: { bundleId },
          query: query
        })
      }

      await store.dispatch('session/fetch', query)

      next()
    }
  },
  {
    path: '/order/:bundleId',
    name: 'order',
    component: () => import('../views/OrderPage.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function hasQueryParams (route) {
  return !!Object.keys(route.query).length
}

router.beforeEach(
  async (to, from, next) => {
    const locale = to.query.locale || i18n.locale
    await loadLanguageAsync(locale)
    i18n.locale = locale
    axios.defaults.headers.common['X-localization'] = i18n.locale
    if (!hasQueryParams(to) && hasQueryParams(from)) {
      await router.push({ ...to, query: from.query })
    } else {
      next()
    }
  }
)

export default router
