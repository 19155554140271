<template>
  <div
    v-if="showCoupons"
  >
    <div
      v-if="showBasePanel"
      class="gift-notification"
    >
      <h3
        class="gift-notification__title"
      >
        {{ $t('coupons.title') }}
      </h3>
      <div
        class="gift-notification__content"
      >
        <div
          class="gift-notification__img"
        >
          <coupons-gift-image />
        </div>
        <div
          class="gift-notification__right-section"
        >
          <p
            class="gift-notification__text"
            v-html="description"
          />
          <button
            class="wfw-button wfw-button--primary gift-notification__button"
            @click="goToCoupons"
          >
            {{ $t('coupons.button-text') }}
          </button>
        </div>
      </div>
    </div>
    <div
      v-else
      class="wfw-coupons__wrapper"
    >
      <div
        class="wfw-coupons__buttons"
      >
        <a
          class="wfw-button wfw-button--primary button-inverse"
          @click="goToCoupons"
        >
          {{ $t('coupons.w4w.to_coupons') }}
        </a>
        <a
          target="_blank"
          :href="$t('coupons.w4w.second_link')"
          class="wfw-button wfw-button--primary"
          @click="toCompetition"
        >
          {{ $t('coupons.w4w.to_competition') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ACTION_CLICK_ON_COUPON_BTN } from '@/constants/flow-log.constants'
import { toBase64 } from '@/services/code.service'
import CouponsGiftImage from '@/components/coupon/CouponsGiftImage'
import _omit from 'lodash.omit'
export default {
  name: 'CouponsPanel',
  components: { CouponsGiftImage },
  computed: {
    showCoupons () {
      const { onCouponPage } = this.$route.query
      return this.$store.state.flow.showCoupons && !onCouponPage
    },
    sessionSlug () {
      return this.$store.state.session.slug
    },
    showBasePanel () {
      const { showW4wCoupons } = this.$store.state.flow
      return !showW4wCoupons
    },
    description () {
      const { voucherText } = this.$store.state.flow
      return voucherText || this.$t('coupons.description')
    }
  },
  methods: {
    goToCoupons () {
      const { sessionSlug } = this
      const { query } = this.$route
      const { locale } = query

      this.$gtm.trackEvent({
        event: 'action_gutscheine'
      })

      if (!sessionSlug) return
      const helperData = _omit(query, ['promo_code', 'order_id', 'order_value', 'order_currency'])
      const base64Query = toBase64(helperData)
      const { VUE_APP_COUPONS_PAGE_URL } = process.env
      const action = ACTION_CLICK_ON_COUPON_BTN
      this.$store.dispatch('session/log', { action })
      window.open(`${VUE_APP_COUPONS_PAGE_URL}/${sessionSlug}?h=${base64Query}&locale=${locale}`, '_blank').focus()

    },
    toCompetition () {
      this.$gtm.trackEvent({
        event: 'action_wettbewerbe'
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .gift-notification {
    margin: 20px 0 10px;
    padding: 16px 16px 16px 4px;
    border: 2px solid var(--highlight-color);

    &__title {
      padding-left: 12px;
      font-weight: 600;
      font-size: 1.285em;
      line-height: 22px;
    }

    &__content {
      display: flex;
      width: 100%;
    }

    &__right-section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
    }

    &__img {
      padding: 12px;
    }

    &__text {
      width: 60%;
      font-weight: 500;
      font-size: 0.857em;
      line-height: 15px;
      color: var(--secondary-color);
    }

    &__button {
      margin-left: auto;
      font-size: 0.857em;
    }

    @media screen and (max-width: 768px) {
      &__text {
        width: 100%;
      }
    }
  }

  .wfw-coupons__wrapper {
    margin-top: 20px;
    margin-bottom: 50px;

    .wfw-coupons__title {
      text-align: center;
      line-height: 150%;
      font-size: 24px;
      font-weight: 700;
    }

    @media screen and (max-width: 576px) {
      margin-bottom: 10px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .wfw-coupons__buttons {
      margin-top: 30px;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      gap: 20px;

      @media screen and (max-width: 576px) {
        flex-direction: column;
        align-items: center;
        gap: 10px;
      }

      .wfw-button {
        box-sizing: border-box;
        width: 280px;
        padding: 19px 0;
        letter-spacing: 1px;
        border: 1px solid var(--highlight-color);
        color: white;
        font-size: 1em;
        font-weight: 700;
        line-height: 15px;

        &.button-inverse {
          color: var(--highlight-color);
          background-color: var(--background-color);
        }
      }
    }
  }
</style>
