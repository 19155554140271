<template>
  <div
    class="container"
  >
    <coupons-panel />
    <success-banner
      v-if="$route.params.success"
    />

    <div
      class="flow-description__wrapper"
      v-html="flow.description"
    />
    <product-bundle-carousel
      v-if="highProducts.length && !hideProducts"
      :products="highProducts"
      :title="$t('first-level-product-title')"
      @sawProducts="handleProductShowing(true, ...arguments)"
    />
    <product-bundle-carousel
      v-if="lowProducts.length && !hideProducts"
      :products="lowProducts"
      :title="$t('second-level-product-title')"
      @sawProducts="handleProductShowing(false, ...arguments)"
    />
  </div>
</template>

<script>
import ProductBundleCarousel from '@/components/product/ProductBundleCarousel'
import CouponsPanel from '@/components/coupon/CouponsPanel'
import SuccessBanner from '@/components/success/SuccessBanner'
import _debounce from 'lodash.debounce'

export default {
  name: 'FlowPage',
  components: { SuccessBanner, CouponsPanel, ProductBundleCarousel },
  data () {
    return {
      showedProducts: []
    }
  },
  computed: {
    flow () {
      return this.$store.state.flow
    },
    highProducts () {
      return this.flow.highLevelProducts
    },
    lowProducts () {
      return this.flow.lowLevelProducts
    },
    hideProducts () {
      const { onCouponPage } = this.$route.query
      return !onCouponPage && this.flow.hideProductsFromStartPage
    },
  },
  watch: {
    showedProducts: {
      handler (value) {
        this.saveShowedProducts(value)
      }
    }
  },
  created () {
    const { query } = this.$route
    const { flow_uuid: flowSlug } = query
    this.$store.dispatch('flow/fetch', flowSlug)
  },
  methods: {
    handleProductShowing (isHighPriority, products) {
      const { showedProducts } = this
      for (const id of products) {
        const productAdded = showedProducts.some(_ => _.id === id && _.is_high_priority === isHighPriority)
        if (productAdded) continue
        this.showedProducts.push({
          id,
          is_high_priority: isHighPriority
        })
      }
    },
    saveShowedProducts: _debounce(function (productBundles) {
      this.$store.dispatch('session/logShowedProducts', productBundles)
    }, 1000)
  }
}
</script>

<style
  scoped
>
.flow-description__wrapper {
  text-align: center;
}
</style>
