export const ACTION_SAW_PRODUCT_BUNDLES = 'embed:saw-product-bundles'
export const ACTION_CLICK_ON_PRODUCT_BUNDLE = 'embed:click-on-product-bundle'
export const ACTION_CLICK_ON_COUPON_BTN = 'embed:click-on-coupon-btn'
export const ACTION_CLICK_ON_BACK_BTN = 'embed:click-on-back-btn'
export const ACTION_MAKE_LEAD = 'embed:make-lead'
export const ACTION_SELECT_VARIATION = 'embed:select-product-variation'
export const ACTION_CLICK_EXTERNAL_LINK = 'embed:click-external-link'
export const ACTION_RESET_WATCHED_PRODUCTS = [
  ACTION_CLICK_ON_PRODUCT_BUNDLE,
  ACTION_CLICK_ON_BACK_BTN,
  ACTION_MAKE_LEAD
]
